<template>
  <div>
    <div id="bar">
      <h1>Vue Email Editor (Demo)</h1>

      <button v-on:click="saveDesign" class="btn btn-default">
        Save Design
      </button>
      <button v-on:click="exportHtml" class="btn btn-primary">
        Export HTML
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",

  methods: {
    editorLoaded() {
      // Pass the template JSON here
      // this.$refs.emailEditor.editor.loadDesign({});
    },
  },
};
</script>
<style lang="scss">
.email-editor {
  min-height: 600px;
  iframe {
    height: 600px !important;
  }
}
</style>
